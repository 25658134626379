<script>
  import { link } from "svelte-routing";
  let date = new Date().getFullYear();

  export let absolute = false;
  export let data;
</script>

<footer
  class="pb-6 {absolute ? 'absolute w-full bottom-0 bg-blueGray-800' : 'relative'}"
>
  <div class="container mx-auto px-4">
    <hr class="mb-6 border-b-1 border-blueGray-600" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-6/12 px-4">
        <div class="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left">
        {#if data}
        {#if data.footer && data.footer.length>0}
          {#if data.footer[0].data.text}
            {#if data.footer[0].data.text}
             {@html data.footer[0].data.text}
            {/if}
          {/if}
          {:else}
            Copyright © {date} &nbsp;
            <a href="https://www.fotomaturak.cz" class="text-white hover:text-blueGray-300 text-sm font-semibold py-1">FotoMaturak.cz</a>
        {/if}
        {/if}
        </div>
      </div>

      <div class="w-full md:w-6/12 px-4">
        <ul class="flex flex-wrap list-none md:justify-end justify-center">
        {#if data}
        {#if data.footer && data.footer.length>0}
     	 	  {#if data.footer[0].data.text}
            {#if data.text.gdpr}
              <li class="text-white hover:text-blueGray-300 text-sm font-semibold block py-1 px-3">
                <a use:link href="/article/gdpr">{data.text.gdpr.title}</a>
              </li>
            {/if}
            {#if data.text.termsAndConditions}
              <li class="text-white hover:text-blueGray-300 text-sm font-semibold block py-1 px-3">
                <a use:link href="/article/termsAndConditions">{data.text.termsAndConditions.title}</a>
              </li>
            {/if}
            {#if data.text.contact}
              <li class="text-white hover:text-blueGray-300 text-sm font-semibold block py-1 px-3">
                <a use:link href="/article/contact">{data.text.contact.title}</a>
              </li>
            {/if}
            {#if data.text.help}
              <li class="text-white hover:text-blueGray-300 text-sm font-semibold block py-1 px-3">
                <a use:link href="/article/help">{data.text.help.title}</a>
              </li>
            {/if}
          {/if}
        {/if}
        {/if}
        </ul>
      </div>
    </div>
  </div>
</footer>
