<script>
  import { onMount } from 'svelte';
  import { Styles, Button } from 'sveltestrap';
  import { api_url } from "./ConfigService.js";
  import { Router, Route, Link } from "svelte-routing";
  import Navbar from "./fotomaturak/Navbar.svelte";
  import Footer from "./fotomaturak/Footer.svelte";
  //import Layout from "./fotomaturak/Layout.svelte";
  import Countdown from "./fotomaturak/Countdown.svelte";
  //import Boxes from "./fotomaturak/Boxes.svelte";
  import CardStats from "./fotomaturak/CardStats.svelte";
  //import VideoLevel from "./fotomaturak/VideoLevel.svelte";
  import Loading from "./components/Loading.svelte";
  import Login from "./pages/Login.svelte";
  import Home from "./pages/Home.svelte";
  import Order from "./pages/Order.svelte";
  import ShareDialog from "./components/shareDialog.svelte";

  export let data=false;
  export let share=false;
  export let addToCartCount=0;
  export let addToCartCountUpdate=0;
  
  export let isOrder=false;
  function setOrder(value) { isOrder=value; return ""; }
  
  onMount(async () => {
    const response = await fetch($api_url+'');
    data=await response.json();
  });

  export let url="";

  function findValueModuls(n, mod) {
    let value="";
    mod.forEach((item, i) => {
      if(item.name==n) { value = item.value; }
    });
    return value;
  }

  function shareDialog() {
    share=true;
  }

</script>

<Styles />
<Router url="{url}">
<div>   
  <Navbar bind:data bind:addToCartCount bind:isOrder />
  <main>
    <section class="relative w-full h-full py-40 min-h-screen">
      <div
        class="absolute top-0 w-full h-full bg-no-repeat bg-full"
        style="background-image: url('/bg.png'); background-attachment: fixed; background-size: cover;"
      ></div>
      <div class="container masterBox mx-auto h-full">
        <div class="flex content-center items-center justify-center h-full">
          <div class="w-full px-4 px-mobile">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div class="flex-auto px-4 lg:px-10 py-10">
              
		  <Route path="order/:id/:auth" let:params>
			<Order id={params.id} auth={params.auth} />	
			{setOrder(true)}
		   </Route> 
				
		  {#if isOrder}
		  
                  {:else if !data}
                		<Loading />
                  {:else if data.logged}
                    <h2>{data.title} {data.school.nazev}</h2>

                    <Home bind:data bind:addToCartCount downloadApi="{findValueModuls('Download_api', data.school.moduls)}" />
                    <Route path="/">
                      <Countdown date="{findValueModuls('Datum', data.school.moduls)}" />

                      <div class="relative pt-8 pb-32 text-lg">
                        <div class="px-3 px-mobile md:px-10 mx-auto w-full">
                          <div>
                         <div class="flex flex-wrap">

	                 <div class="w-full lg:w-4/12 xl:w-4/12 px-4 px-mobile pb-4">
	                   <CardStats
	                     statSubtitle="Fotografie"
	                     statIconName="fa fa-camera"
	                     statIconColor="bg-red-500"
	                   >
	                     {@html findValueModuls('Foto', data.school.moduls)}
	                     <br>
	                     <div class="classButton">
	                       {#each data.album as al}
	                         <Link to="album/{al.id}/{al.kod}"><Button color="primary" size="lg" class="mx-4 my-1 p-2">{al.nazev}</Button></Link><br />
	                       {/each}
	                     </div>
	                   </CardStats>
	                 </div>
		          {#if findValueModuls('Video', data.school.moduls)}
		                  <div class="w-full lg:w-8/12 xl:w-8/12 px-4 px-mobile pb-4">
		                    <CardStats
		                      statSubtitle="Video"
		                      statIconName="fa fa-regular fa-video"
		                      statIconColor="bg-orange-500"
		                    >
		                      <!--<center><VideoLevel /></center>-->
		                      {@html findValueModuls('Video', data.school.moduls)}
		                    </CardStats>
		                  </div>
		         {/if}
			       {#if findValueModuls('Fotokoutek', data.school.moduls)}
		                 <div class="w-full lg:w-4/12 xl:w-4/12 px-4 px-mobile pb-4">
		                   <CardStats
		                     statSubtitle="Fotokoutek"
		                     statIconName="fa fa-users"
		                     statIconColor="bg-pink-500"
		                   >
		                     {@html findValueModuls('Fotokoutek', data.school.moduls)}
		                   </CardStats>
		                 </div>
		          {/if}
		          {#if findValueModuls('Info', data.school.moduls)}
		                 <div class="w-full lg:w-4/12 xl:w-4/12 px-4 px-mobile pb-4">
		                   <CardStats
		                     statSubtitle="Info"
		                     statIconName="fa fa-info"
		                     statIconColor="bg-emerald-500"
		                   >
		                     {@html findValueModuls('Info', data.school.moduls)}
		                   </CardStats>
		                 </div>
		          {/if}

<!--

                            <div class="w-full px-4 pb-4">
                              <CardStats
                                statSubtitle="Video"
                                statIconName="fa fa-regular fa-video"
                                statIconColor="bg-orange-500"
                              >
                                <!--<center><VideoLevel /></center>-->
<!--                                {@html findValueModuls('Video', data.school.moduls)}
                              </CardStats>
                            </div>
                            <div class="flex flex-wrap">
                              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                                <CardStats
                                  statSubtitle="Fotografie"
                                  statIconName="fa fa-camera"
                                  statIconColor="bg-red-500"
                                >
                                  {@html findValueModuls('Foto', data.school.moduls)}
                                  <div class="classButton">
                                    {#each data.album as al}
                      								<Link to="album/{al.id}/{al.kod}"><Button color="primary" size="lg" class="mx-4 my-1 p-2">{al.nazev}</Button></Link>
                      							{/each}
                                  </div>
                                </CardStats>
                              </div>
                              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                                <CardStats
                                  statSubtitle="Fotokoutek"
                                  statTitle="924"
                                  statArrow="down"
                                  statPercent="1.10"
                                  statPercentColor="text-orange-500"
                                  statDescripiron="Since yesterday"
                                  statIconName="fa fa-users"
                                  statIconColor="bg-pink-500"
                                ><ul class="ml-2 list-info">
                                  <li>Na plese budete mít k dispozici náš fotokoutek</li>
                                  <li>Platí se zhotovené fotografie přímo na místě</li>
                                </ul>
                                </CardStats>
                              </div>
                              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                                <CardStats
                                  statSubtitle="Info"
                                  statIconName="fa fa-info"
                                  statIconColor="bg-emerald-500"
                                >
                                <h3>Kdo se vám bude na plese věnovat?</h3>
                                  <div class="flex ml-3">
                                    <img src="/holomek.jpg" alt="Fotograf Jan Holomek" title="Fotograf Jan Holomek" class="w-16 h-24 rounded-full border-2 border-blueGray-50 shadow -ml-4">
                                    <img src="/tyrl.jpg" alt="Kameraman Jan Týřl" title="Kameraman Jan Týřl" class="w-16 h-24 rounded-full border-2 border-blueGray-50 shadow -ml-4">
                                  </div>
                                </CardStats>
                              </div>
                              -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--<Boxes />-->
                    </Route>

                	{:else if typeof data.logged !== 'undefined'}
                		<Login bind:data />
                	{:else}
                		<Loading />
                	{/if}
                  </div>
                </div>
              </div>
            </div>
          </div>
        {#if data}
          <Footer bind:data absolute="true" />
        {/if}
    </section>
  </main>
</div>
</Router>
<ShareDialog bind:data bind:open={share} />

<style>
h2 { font-size:20px; }
h3 { font-size:16px; font-weight:bold; margin-bottom:10px; }
.list-info { list-style-type:circle; }
.masterBox { min-width:80%; max-width:1715px; }
@media (max-width: 768px) {
	.px-mobile { padding-left:0px!important; padding-right:0px!important; }
}
</style>
