import { writable } from 'svelte/store';

export let api="/api/";
export let path_url="/";
if((window.location.href).includes('localhost:5000')) { 
	api="https://shop.fotomaturak.cz/api/"; 
	path_url = "https://shop.fotomaturak.cz/";
}

export let path = writable(path_url);
export let api_url = writable(api);
export let bgColor = writable("rgba( 13, 110, 253, .9)");
export let errorColor = writable("red");
